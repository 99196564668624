import {
	createListenerMiddleware,
	type AnyAction,
	type ListenerMiddlewareInstance,
	type ThunkDispatch,
} from "@reduxjs/toolkit";
import { createAddress, updateAddress } from "../addressApi";
import { createCompany, updateCompany } from "../companyApi";
import { updateOrderAddress } from "../orderAddressApi/orderAddressApi";
import { updateOrder } from "../orderApi";
import {
	approveQuote,
	cancelOrder,
	declineQuote,
	rejectOrder,
	requestCallback,
} from "../orderStateTransitionApi";
import { updatePart } from "../partApi";
import { updateRfq } from "../rfqApi";
import { createUser, updateProfileUser } from "../userApi";
import { closeForm, setFormError, submitForm } from "./formManager";

export const formManagerMiddleware: ListenerMiddlewareInstance<
	unknown,
	ThunkDispatch<unknown, unknown, AnyAction>,
	unknown
> = createListenerMiddleware();

const endpoints = {
	// RFQ Api
	updateRfq: {
		init: updateRfq.initiate,
		matchFullfilled: updateRfq.matchFulfilled,
		matchRejected: updateRfq.matchRejected,
	},

	// Order state transition api
	cancelOrder: {
		init: cancelOrder.initiate,
		matchFullfilled: cancelOrder.matchFulfilled,
		matchRejected: cancelOrder.matchRejected,
	},
	rejectOrder: {
		init: rejectOrder.initiate,
		matchFullfilled: rejectOrder.matchFulfilled,
		matchRejected: rejectOrder.matchRejected,
	},
	declineQuote: {
		init: declineQuote.initiate,
		matchFullfilled: declineQuote.matchFulfilled,
		matchRejected: declineQuote.matchRejected,
	},
	approveQuote: {
		init: approveQuote.initiate,
		matchFullfilled: approveQuote.matchFulfilled,
		matchRejected: approveQuote.matchRejected,
	},
	requestCallback: {
		init: requestCallback.initiate,
		matchFullfilled: requestCallback.matchFulfilled,
		matchRejected: requestCallback.matchRejected,
	},

	// Order Address API
	updateOrderAddress: {
		init: updateOrderAddress.initiate,
		matchFullfilled: updateOrderAddress.matchFulfilled,
		matchRejected: updateOrderAddress.matchRejected,
	},

	// Address API
	updateAddress: {
		init: updateAddress.initiate,
		matchFullfilled: updateAddress.matchFulfilled,
		matchRejected: updateAddress.matchRejected,
	},
	createAddress: {
		init: createAddress.initiate,
		matchFullfilled: createAddress.matchFulfilled,
		matchRejected: createAddress.matchRejected,
	},

	// Order API
	updateOrder: {
		init: updateOrder.initiate,
		matchFullfilled: updateOrder.matchFulfilled,
		matchRejected: updateOrder.matchRejected,
	},

	// Company API
	updateCompany: {
		init: updateCompany.initiate,
		matchFullfilled: updateCompany.matchFulfilled,
		matchRejected: updateCompany.matchRejected,
	},
	createCompany: {
		init: createCompany.initiate,
		matchFullfilled: createCompany.matchFulfilled,
		matchRejected: createCompany.matchRejected,
	},

	// User API
	createUser: {
		init: createUser.initiate,
		matchFullfilled: createUser.matchFulfilled,
		matchRejected: createUser.matchRejected,
	},
	updateProfileUser: {
		init: updateProfileUser.initiate,
		matchFullfilled: updateProfileUser.matchFulfilled,
		matchRejected: updateProfileUser.matchRejected,
	},

	// Part API
	updatePart: {
		init: updatePart.initiate,
		matchFullfilled: updatePart.matchFulfilled,
		matchRejected: updatePart.matchRejected,
	},
};

export type Endpoints = keyof typeof endpoints;

// Submit form logic
formManagerMiddleware.startListening({
	actionCreator: submitForm,
	// this function will get called
	effect: async (action, listenerApi) => {
		let onFulfilled: any;
		let onRejected: any;
		let sub: any;
		// where we read which endpoint to trigger from action payload
		if (action.payload.isPOST) {
			const { init, matchFullfilled, matchRejected } =
				endpoints[action.payload.postEndpoint as Endpoints];
			onFulfilled = matchFullfilled;
			onRejected = matchRejected;
			// trigger the POST api endpoint with merged data
			// from entity template and form adata
			sub = listenerApi.dispatch(
				(init as any)({
					...action.payload.postEntityTemplate,
					...action.payload.submittedEntity,
				})
			);
		} else {
			const { init, matchFullfilled, matchRejected } =
				endpoints[action.payload.patchEndpoint as Endpoints];
			onFulfilled = matchFullfilled;
			onRejected = matchRejected;
			// trigger the PATCH api endpoint a tuple of entity id and an entity
			sub = listenerApi.dispatch(
				(init as any)([
					action.payload.editedEntity.id,
					action.payload.submittedEntity,
				])
			);
		}

		// and listen for status changes
		const successTask = listenerApi.fork(async (forkApi) => {
			if (await listenerApi.condition(onFulfilled)) {
				sub.unsubscribe();
				if (action.payload.onSubmit) action.payload.onSubmit();
				listenerApi.dispatch(closeForm({ isError: false, isSucces: true }));
				rejectTask.cancel();
				return;
			}
		});
		const rejectTask = listenerApi.fork(async (forkApi) => {
			if (await listenerApi.condition(onRejected)) {
				sub
					.unwrap()
					.catch((error: { data: { error: string; message: string[] } }) => {
						listenerApi.dispatch(setFormError({ ...error.data }));
					});
				sub.unsubscribe();
				successTask.cancel();
				return;
			}
		});
		void (await Promise.race([successTask.result, rejectTask.result]));
	},
});
