import Script from "next/script";

const Clarity = () => {
	const clarityId = process.env.NEXT_PUBLIC_CLARITY_ID;

	if (!clarityId) {
		console.warn(
			"Microsoft Clarity ID is not defined. Clarity script will not be loaded."
		);
		return null;
	}

	if (process.env.NODE_ENV === "development") {
		console.debug(
			"Microsoft Clarity is disabled in development. Clarity script will not be loaded."
		);
		return null;
	}

	return (
		<Script
			id="microsoft-clarity-init"
			strategy="afterInteractive"
			dangerouslySetInnerHTML={{
				__html: `
								var _hsp = window._hsp = window._hsp || [];
								_hsp.push(['addPrivacyConsentListener', (consent) => {
    							if (consent && (consent.allowed || (consent.categories && consent.categories.analytics))) {
                		(function(c,l,a,r,i,t,y){
                		    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                		    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                		    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
												})(window, document, "clarity", "script", "${clarityId}");
									 }
    						}])
                `,
			}}
		/>
	);
};

export default Clarity;
