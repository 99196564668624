import Script from "next/script";

declare global {
	interface Window {
		dataLayer?: Object[];
	}
}

const GoogleAnalytics = () => {
	const gaId = process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID;

	if (!gaId) {
		console.warn(
			"Google Analytics ID is not defined. Analytics will not be loaded."
		);
		return null;
	}

	if (process.env.NODE_ENV === "development") {
		console.debug(
			"Google Analytics is disabled in development. Analytics will not be loaded."
		);
		return null;
	}

	return (
		<>
			<Script
				id="_next-ga-consent"
				strategy="afterInteractive"
				dangerouslySetInnerHTML={{
					__html: `
						window.dataLayer = window.dataLayer || [];
						function gtag(){window.dataLayer.push(arguments);}
						gtag('consent', 'default', {
							'ad_storage': 'denied',
							'analytics_storage': 'denied',
							'ad_user_data': 'denied',
    					'ad_personalization': 'denied',
						});

						var _hsp = window._hsp = window._hsp || []; 
						_hsp.push(['addPrivacyConsentListener', function(consent) {
						    var hasAnalyticsConsent = consent && (consent.allowed || (consent.categories && consent.categories.analytics));
						    var hasAdsConsent = consent && (consent.allowed || (consent.categories && consent.categories.advertisement));

						    gtag('consent', 'update', {
						        'ad_storage': hasAdsConsent ? 'granted' : 'denied',
						        'analytics_storage': hasAnalyticsConsent ? 'granted' : 'denied',
						        'ad_user_data': hasAdsConsent ? 'granted' : 'denied',
						        'ad_personalization': hasAdsConsent ? 'granted' : 'denied'
						    });
						}]);

								`,
				}}
			/>
			<Script
				id="_next-ga"
				strategy="afterInteractive"
				src={`https://www.googletagmanager.com/gtag/js?id=${gaId}`}
			/>
			<Script
				id="_next-ga-init"
				strategy="afterInteractive"
				dangerouslySetInnerHTML={{
					__html: `
						window.dataLayer = window.dataLayer || [];
						function gtag(){window.dataLayer.push(arguments);}
						gtag('js', new Date());
						gtag('config', '${gaId}', {
							page_path: window.location.pathname,
							hostname: window.location.hostname,
						});
								`,
				}}
			/>
		</>
	);
};

export default GoogleAnalytics;
