import type { PayloadAction } from "@reduxjs/toolkit";
import { Selector, createSelector, createSlice } from "@reduxjs/toolkit";
import type { Session } from "next-auth";
import { HYDRATE } from "next-redux-wrapper";
import { RootState } from "../store";

import type { User } from "@rototip/lib-user/db-client";

export type UserWithImpersonation = User & {
	impersonatedBy?: User;
};

export type SessionState = {
	session: Session & { expires?: string };
	cookies?: string;
};

export type UserPatchPayload = Partial<User>;

const initialState: SessionState = {
	session: {
		expires: "",
		// @ts-ignore complaining about user being null, and not undefined
		user: null,
	},
};

export const sessionSlice = createSlice({
	name: "system:session",
	initialState,
	reducers: {
		setSession: (state, action: PayloadAction<Session>) => {
			state.session = action.payload;
			return state;
		},
		patchUserDetails: (state, action: PayloadAction<UserPatchPayload>) => {
			state.session.user = { ...state.session.user, ...action.payload };
			return state;
		},
		setCookies: (state, action: PayloadAction<string>) => {
			state.cookies = action.payload;
			return state;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			(action) => action.type === HYDRATE,
			(state, action) => {
				state.cookies = action.payload[sessionSlice.name].cookies;
			}
		);
	},
});

// Action creators are generated for each case reducer function
export const { setSession, patchUserDetails, setCookies } =
	sessionSlice.actions;

export default sessionSlice.reducer;

// selectors
const selectSelf: Selector<RootState, RootState> = (state: RootState) => state;

export const sessionSelector: Selector<RootState, SessionState> =
	createSelector(selectSelf, (state) => state[sessionSlice.name]);

export const userSelector: Selector<
	RootState,
	UserWithImpersonation | undefined
> = createSelector(
	sessionSelector,
	(sessionState) =>
		sessionState.session?.user as UserWithImpersonation | undefined
);

export const userRolesSelector: Selector<RootState, string[]> = createSelector(
	userSelector,
	(user) => (user?.roles ? JSON.parse(user.roles) : [])
);

export const sessionCookiesSelector: Selector<RootState, string | undefined> =
	createSelector(sessionSelector, (sessionState) => sessionState.cookies);
